<template>
  <div class="container" v-on:scroll="handleScroll">
    666
    <div
      v-for="(item, index) in contentArray"
      :key="index"
      class="content"
      v-show="showContent[index]">
      {{ item }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contentArray: ["内容1", "内容2", "内容3", "内容4"], // 根据实际情况设置内容数组
      showContent: [], // 初始时都为false，表示内容都隐藏
    };
  },
  mounted() {
    // 初始化showContent数组，全部设置为false
    this.showContent = Array(this.contentArray.length).fill(false);
  },
  methods: {
    handleScroll() {
      const container = this.$el;
      const visibleHeight = container.clientHeight;
      const scrollTop = container.scrollTop;
      // 根据滚动距离和元素可见高度，计算应该显示的内容
      this.showContent = this.contentArray.map((_, index) => {
        const div = container.children[index + 1]; // 由于第一个子元素是容器本身，所以需要加1
        const divTop = div.offsetTop;
        const divHeight = div.clientHeight;
        // 判断该内容是否在可见范围内
        return (
          divTop <= scrollTop + visibleHeight && scrollTop <= divTop + divHeight
        );
      });
    },
  },
};
</script>
<style>
.container {
  height: 8000px;
}
.content {
  height: 800px;
  background-color: red;
}
</style>
